export const formatDuration = (minutes) => {
  if (minutes <= 0) return "0min";

  const years = Math.floor(minutes / (60 * 24 * 365));
  const days = Math.floor((minutes % (60 * 24 * 365)) / (60 * 24));
  const hours = Math.floor((minutes % (60 * 24)) / 60);
  const mins = (minutes % 60).toFixed(2).replace(/\.00$/, ""); // Ensures at most 2 decimal places

  let result = [];
  if (years > 0) result.push(`${years}yr${years > 1 ? "s" : ""}`);
  if (days > 0) result.push(`${days}day${days > 1 ? "s" : ""}`);
  if (hours > 0) result.push(`${hours}hr${hours > 1 ? "s" : ""}`);
  if (parseFloat(mins) > 0) result.push(`${mins}min`);

  return result.join(" ");
};