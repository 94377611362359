import React from 'react'
import GridCard from './GridCard'
import { useDispatch, useSelector } from 'react-redux';
import GridSkeleton from './GridSkeleton';
import { getUserProfile } from '../../../redux/actions/UsersActions';
import { useMediaQuery } from 'react-responsive';


const  GridView = (props) => {

    const { users, gettingUsers } = useSelector(state => state.users)
    console.log(users, 'ud')
    const { openModal } = props
    const dispatch = useDispatch()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const getUserDetails = (id) => {
        dispatch(getUserProfile(id))
        openModal()
    }

    const renderUsers = () => {
        if (gettingUsers) {
            return <GridSkeleton />;
        }

        if (users && users.users && users.users.length !== 0) {
            return users.users.map((item) => (
                <div key={item?.id}>
                    <GridCard
                        item={item}
                        onClick={() => getUserDetails(item?.id)}
                        itemID={item?.id}
                    />
                </div>
            ));
        }

        if (users && users.length !== 0) {
            return users.map((item) => (
                <div key={item?.id}>
                    <GridCard
                        item={item}
                        onClick={() => getUserDetails(item?.id)}
                        itemID={item?.id}
                    />
                </div>
            ));
        }

        return (
            <div style={{ textAlign: "center", width: "100%" }}>
                <p className={"boldText f20"}>No data</p>
            </div>
        );
    };

    return (
        <>

            <section className='userManagementGridView'>
                {renderUsers()}
            </section>
        </>
    )
}

export default GridView