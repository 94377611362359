import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/orderManagementStyles.css';
import moment from 'moment';
import { getStatusConfig } from '../../../utils/Helpers';
import { formatDuration } from '../../../utils/formatDuration';


function getStatusFromTimeDifference(timeDifference) {
    const roundedTime = timeDifference.toFixed(2); // Round to 2 decimal places

    if (timeDifference <= 30) {
        return { status: `Normal ${formatDuration(roundedTime)}`, color: 'rgba(102, 203, 159, 1)', bgColor: 'rgba(222, 255, 238, 1)', time: roundedTime };
    } else if (timeDifference > 30 && timeDifference <= 40) {
        return { status: `Potential Delay ${formatDuration(roundedTime)}`, color: 'rgba(255, 213, 79, 1)', bgColor: 'rgba(255, 248, 225, 1)', time: roundedTime };
    } else if (timeDifference > 40 && timeDifference <= 60) {
        return { status: `Delay ${formatDuration(roundedTime)}`, color: 'rgba(255, 213, 79, 1)', bgColor: 'rgba(255, 248, 225, 1)', time: roundedTime };
    } else if (timeDifference > 60) {
        return { status: `Critical ${formatDuration(roundedTime)}`, color: 'rgba(241, 96, 99, 1)', bgColor: 'rgba(255, 230, 228, 1)', time: roundedTime };
    } else {
        return { status: `Unknown classification ${formatDuration(roundedTime)}`, color: 'rgba(241, 96, 99, 1)', bgColor: 'rgba(255, 230, 228, 1)', time: roundedTime };
    }
}

const ClassifyOrder = (whenCustomerPaid, whenOrderDelivered, orderStatus) => {
    const currentTime = new Date().getTime();
    const paymentTime = new Date(whenCustomerPaid).getTime();
    const deliveredTime = new Date(whenOrderDelivered).getTime();
    const timeDifference = (currentTime - paymentTime) / 60000;
    const deliveredTimeDifference = (deliveredTime - paymentTime) / 60000;

    if (!whenCustomerPaid) {
        return { status: `Unpaid`, color: 'rgba(166, 183, 212, 1)', bgColor: 'rgba(235, 242, 250, 1)' };
    }
    if (whenOrderDelivered) {
        return { status: `Delivered ${deliveredTimeDifference.toFixed(2)} mins`, color: '#000', bgColor: 'rgba(240, 241, 242, 1)' };
    }
    if (orderStatus === 'ORDER_CANCELLED') {
        return { status: `Cancelled`, color: '#000', bgColor: 'rgba(240, 241, 242, 1)' };
    }
    return getStatusFromTimeDifference(timeDifference);
};

const ActiveOrdersCard = (props) => {

    const { data } = props

    // console.log(data)
    const navigate = useNavigate()
    const orderClassification = ClassifyOrder(data?.whenCustomerPayedForOrder, data?.whenOrderGotDelivered, data?.orderStatus);
    const statusConfig = getStatusConfig(data?.orderStatus);


    const getSingleOrder = (val) => {
        navigate('/orderManagement/orderDetails', {
            state: {
                id: data?.id,
            }
        })

    }
    // 20 5months 





    return (
        <section className={'activeOrdersCard'}>
            <div className={'flexRow justifyBetween activeOrdersCardHeader'}>
                <div className={'flexRow alignCenter'}>
                    <div className={'userOrderAvatar'}>
                        <img src={`${data?.user?.avatar}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
                    </div>
                    <div>
                        <p className={'f12 boldText'}>
                            {data?.user?.firstName || 'No name'}
                            <span style={{ fontSize: '8px', color: '#919294' }} > - CUSTOMER</span>
                        </p>
                        <p className={'f12 regularText'}>+{data?.user?.phoneNumber}</p>
                    </div>
                </div>
                <div>
                    <p
                        className={'orderStatusContainer textCenter f10 regularText'}
                        style={{
                            backgroundColor: statusConfig.bgColor,
                            color: statusConfig.textColor,
                            // width: 'auto',
                        }}
                    >
                        {statusConfig.text}
                    </p>
                    <p className={'f10 regularText'} style={{ paddingTop: '5px' }}>
                        {moment(data?.createdAt).format('YYYY-MM-DD hh:mm')}
                    </p>
                </div>



            </div>
            <div style={{ marginTop: '16px', display: 'grid', gridTemplateColumns: '90% 10%', gap: '16px', alignItems: 'center' }}>
                <div sty>
                    <div className={'flexRow alignCenter justifyBetween'} style={{ marginBottom: '4px' }}>
                        <p className={'boldText f14'}>{data?.originBusinessName}</p>
                        <p className={'semiBoldText f14'}>₦{data?.totalItemsPrice}</p>
                    </div>
                    <div>
                        <ul>
                            {data?.orderItems?.map((item, index) => {
                                return (
                                    <li className={'flexRow alignCenter justifyBetween f12 regularText'} style={{ marginBottom: '4px' }} key={index}>
                                        {item?.name || 'No name'}
                                        <span style={{ color: '#696A6B' }}>₦{item?.price}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div >
                    <p></p>
                </div>

            </div>
            <div className={'flexRow justifyBetween alignCenter activeOrdersCardFooter'}>
                <div className={'flexRow alignCenter'}>
                    <div className={'userOrderAvatar'}>
                        <img src={`${data?.rider?.user?.avatar}`} style={{ width: '100%', height: '100%', borderRadius: '100px' }} />
                    </div>
                    <div>
                        <p className={'f12 boldText'}>
                            {data?.rider?.user?.firstName || 'No name'}
                            <span style={{ fontSize: '8px', color: '#919294' }} > - COURIER</span>
                        </p>
                        <p className={'f12 regularText'}>+234 904 3349 1754</p>
                    </div>
                </div>
                <div className={'activeOrderView'}>
                    <p className={'semiBoldText f12'} onClick={getSingleOrder} style={{ cursor: 'pointer' }}>View</p>
                </div>
            </div>
            <div style={{ marginTop: '10px' }}>
                <p style={{ backgroundColor: orderClassification.bgColor, margin: '0', color: orderClassification.color, minWidth: '125px' }}
                    className={'orderStatusContainer regularText f12 textCenter'}>
                    {orderClassification.status}
                </p>
            </div>
        </section>
    )
}

export default ActiveOrdersCard