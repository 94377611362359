import React, { useState } from 'react';
import InfoIcon from '../assets/images/icons/infoIcon.svg';
import MultiButton from '../utils/MultiButton';
import { Filter, InviteUserIcon } from '../assets/images/icons';
import { usersFilter } from '../utils/Helpers';
import { useDispatch } from 'react-redux';
import { getUserWaitList, getUsersByType } from '../redux/actions/UsersActions';
import AllUsersFilter from '../components/widgets/AllUsersFilter';
import CustomerFilter from '../components/widgets/CustomerFilter';
import VendorFilter from '../components/widgets/VendorsFilter';
import RiderFilter from '../components/widgets/RidersFilter';
import { UPDATE_META } from '../redux/Types';
import { useMediaQuery } from 'react-responsive';



const UserManagementNav = (props) => {

    const [selectedTab, setSelectedTab] = useState('All')
    const [showFilter, setShowFilter] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const toggle = () => {
        setShowFilter(prevState => !prevState)
    }


    const dispatch = useDispatch()
    const toggleTabs = (val) => {
        setSelectedTab(val)
        if (val === 'Waitlist') {
            dispatch(getUserWaitList())
        } else {
            dispatch(getUsersByType(val))
            dispatch({ type: UPDATE_META, payload: val })

        }
    }
    const { open } = props

    const renderTabs = () => {
        return usersFilter?.map((item, index) => {
            return (
                <li key={index} className={`f14 regularText ${selectedTab === item ? 'activeFilter' : 'inactiveFilter'}`} onClick={() => toggleTabs(item)} style={{ cursor: 'pointer' }}>{item}</li>
            )
        })
    }

    const renderFilters = () => {
        if (showFilter && selectedTab === 'All') {
            return (
                <AllUsersFilter />
            )
        }
        if (showFilter && selectedTab === 'Customers') {
            return (
                <CustomerFilter />
            )
        }
        if (showFilter && selectedTab === 'Vendors') {
            return (
                <VendorFilter />
            )
        }

        if (showFilter && selectedTab === 'Riders') {
            return (
                <RiderFilter />
            )
        }

    }


    return (
        <nav className={'usersNavContainer'}>
            <section className={'flexRow justifyBetween flexWrap'}>
                <div style={{ marginBottom: '10px' }}>
                    <div className={'flexRow alignCenter'}>
                        <h3 className={'f24 boldText '} style={{ color: '#16192C' }}>Users</h3>
                        <img src={InfoIcon} alt='icon' style={{ marginLeft: '10px' }} />
                    </div>
                    <p className={'f16 regularText'} style={{ color: '#16192C' }}>Create & Manage users </p>
                </div>
                <div className={'flexRow alignCenter'}>
                    {/* <div style={{ marginRight: '24px' }}> */}
                    {/* <MultiButton title={'Create user'} icon={PlusIcon} bgColor={'#FFA024'} height={'40px'} width={'137px'} /> */}
                    {/* </div> */}
                    <div >
                        <MultiButton title={'Invite user'} icon={InviteUserIcon} bgColor={'#FFF'} height={'40px'} width={'fit-content'} color={'#000'} brd={'1px solid #999CA0'} onClick={open} />
                    </div>
                </div>
            </section>
            <section style={{ marginTop: '30px' }} className={'flexRow alignCenter justifyBetween flexWrap'}>
                <div className={'flexRow alignCenter'} style={{ gap: '12px', marginBottom: '10px' }}>
                    {renderTabs()}
                </div>
                <div>
                    <div className={'flexRow alignCenter'} onClick={toggle} style={{ cursor: 'pointer' }}>
                        <img src={Filter} alt='icon' />
                        <p className={'f14 semiBoldText'} style={{ color: '#565758', marginLeft: '10px' }}>Filter by</p>
                    </div>
                </div>
            </section>
            <section>
                <div>
                    {renderFilters()}
                </div>
            </section>
        </nav>
    )
}

export default UserManagementNav